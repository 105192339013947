var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("WidgetFrame", {
    attrs: { contentClass: "overflow-unset" },
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [_vm._v(" " + _vm._s(_vm.$t("staff.title")) + " ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "settings" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.bookings,
                      callback: function ($$v) {
                        _vm.bookings = $$v
                      },
                      expression: "bookings",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("booking.widget_title")))]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    model: {
                      value: _vm.tasks,
                      callback: function ($$v) {
                        _vm.tasks = $$v
                      },
                      expression: "tasks",
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("task.widget_title")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "input-group project-staff-input-group mb-3" },
              [
                _c("div", { staticClass: "input-group-prepend" }, [
                  _c(
                    "label",
                    {
                      staticClass: "input-group-text",
                      attrs: { for: "staffType" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("dashboard.widgets.view_by")) + " "
                      ),
                    ]
                  ),
                ]),
                _c("multiselect", {
                  staticClass: "custom-dropdown-options enable-option-icon",
                  attrs: {
                    "max-height": 300,
                    options: _vm.optionTypes.map((i) => i.key),
                    "custom-label": _vm.getTypeOptionLabel,
                    placeholder: "",
                    searchable: false,
                    "allow-empty": false,
                    showLabels: false,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "option",
                      fn: function (props) {
                        return [
                          _vm.type == props.option
                            ? _c("font-awesome-icon", {
                                staticClass: "selected-option-icon",
                                attrs: { icon: ["far", "check"] },
                              })
                            : _vm._e(),
                          _c("span", { staticClass: "option__title" }, [
                            _vm._v(
                              _vm._s(_vm.getTypeOptionLabel(props.option))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                }),
              ],
              1
            ),
            _vm.loaded
              ? _c("StaffList", {
                  attrs: {
                    staffs: _vm.staffs,
                    sortKey: _vm.profile.sortKey,
                    sortOrder: _vm.profile.sortOrder,
                    columns: _vm.profile.columns,
                  },
                  on: {
                    sortChanged: _vm.onSortChanged,
                    columnVisible: _vm.onColumnVisible,
                    openStaff: _vm.onOpenStaff,
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }