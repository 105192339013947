var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "WidgetFrame",
    {
      class: _vm.sizeClass,
      on: {
        onResize: _vm.onResize,
        onOrientationChange: _vm.onOrientationChange,
      },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [
              _vm._v(" " + _vm._s(_vm.$t("project.display.progress")) + " "),
            ]
          },
          proxy: true,
        },
        {
          key: "content",
          fn: function () {
            return [
              !_vm.loaded
                ? [
                    _c("div", { staticClass: "status-message" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                : [
                    _vm.uuId
                      ? [
                          _c("div", { staticClass: "project-link" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push(
                                      "/projects/summary/" + _vm.uuId
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-clamp",
                                  { attrs: { autoresize: "", "max-lines": 1 } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.pProject.name) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "summary-text" },
                      [
                        _vm.notStarted
                          ? [
                              _c("i", {
                                staticClass: "far fa-minus-square",
                                style: { color: "var(--status-orange)" },
                              }),
                              _c(
                                "v-clamp",
                                { attrs: { autoresize: "", "max-lines": 1 } },
                                [_vm._v(_vm._s(_vm.$t("task.not_started")))]
                              ),
                            ]
                          : _vm.good
                          ? [
                              _c("i", {
                                staticClass: "far fa-circle-check",
                                style: { color: "var(--status-green)" },
                              }),
                              _c(
                                "v-clamp",
                                { attrs: { autoresize: "", "max-lines": 1 } },
                                [_vm._v(_vm._s(_vm.$t("project.on_schedule")))]
                              ),
                            ]
                          : _vm.behind
                          ? [
                              _c("i", {
                                staticClass: "fas fa-triangle-exclamation",
                                style: { color: "var(--status-red)" },
                              }),
                              _c(
                                "v-clamp",
                                { attrs: { autoresize: "", "max-lines": 1 } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("project.behind_schedule"))
                                  ),
                                ]
                              ),
                            ]
                          : _vm.ahead
                          ? [
                              _c("i", {
                                staticClass: "far fa-circle-check",
                                style: { color: "var(--status-green)" },
                              }),
                              _c(
                                "v-clamp",
                                { attrs: { autoresize: "", "max-lines": 1 } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("project.ahead_of_schedule"))
                                  ),
                                ]
                              ),
                            ]
                          : _vm.complete
                          ? [
                              _c("i", {
                                staticClass: "far fa-circle-check",
                                style: { color: "var(--status-green)" },
                              }),
                              _c(
                                "v-clamp",
                                { attrs: { autoresize: "", "max-lines": 1 } },
                                [_vm._v(_vm._s(_vm.$t("project.completed")))]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "progress-chart-container" }, [
                      _c(
                        "div",
                        {
                          ref: "actualcontainer",
                          staticStyle: { position: "relative" },
                        },
                        [_c("canvas", { ref: "chartActual" })]
                      ),
                      _c(
                        "div",
                        {
                          ref: "plannedcontainer",
                          staticStyle: { position: "relative" },
                        },
                        [_c("canvas", { ref: "chartPlanned" })]
                      ),
                    ]),
                  ],
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v("> ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }