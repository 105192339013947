<template>
  <WidgetFrame @onResize="onResize" contentClass="overflow-unset">
    <template v-slot:title>
      {{ $t('staff.title') }}
    </template>
    <template v-slot:content>
      
      <div class="settings">
        <b-form-checkbox v-model="bookings">{{ $t('booking.widget_title') }}</b-form-checkbox>
        <b-form-checkbox v-model="tasks">{{ $t('task.widget_title') }}</b-form-checkbox>
      </div>
      <div class="input-group project-staff-input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="staffType">
            {{$t('dashboard.widgets.view_by')}}
          </label>
        </div>
        <multiselect v-model="type" class="custom-dropdown-options enable-option-icon"
          :max-height="300"
          :options="optionTypes.map(i => i.key)"
          :custom-label="getTypeOptionLabel"
          :placeholder="''"
          :searchable="false" 
          :allow-empty="false"
          :showLabels="false">
          <template slot="option" slot-scope="props">
            <font-awesome-icon class="selected-option-icon" v-if="type == props.option" :icon="['far', 'check']" />
            <span class="option__title">{{ getTypeOptionLabel(props.option) }}</span>
          </template>
        </multiselect>
      </div>
      <StaffList
          v-if="loaded"
          :staffs="staffs"
          :sortKey="profile.sortKey"
          :sortOrder="profile.sortOrder"
          :columns="profile.columns"
          @sortChanged="onSortChanged"
          @columnVisible="onColumnVisible"
          @openStaff="onOpenStaff"
          />
    </template>
  </WidgetFrame>
</template>
<script>
  import { staffService } from '@/services';
  import Multiselect from 'vue-multiselect';

  export default {
    name: 'ProjectStaffWidget',
    components: {
      WidgetFrame: () => import("@/components/Dashboard/WidgetFrame"),
      StaffList: () => import('@/components/Dashboard/Widget/List/StaffList.vue'),
      Multiselect
    },
    props: {
      profile:    { type: Object, required: true },
      project:      { type: Object, required: true },
      refresh:    { type: Boolean, default: false }
    },
    watch: {
      type(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      },
      bookings(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      },
      tasks(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      },
      refresh(newValue, oldValue) {
        if (newValue) {
          this.buildData();
          this.$emit('refreshed');
        }
      }
    },
    data() {
      return {
        loaded: false,
        staffs: [],
        type: null,
        sortKey: null,
        sortOrder: null,
        bookings: true,
        tasks: true
      };
    },
    created() {
      // Use 'all' default type if profile doesn't have a valid key
      const pType = this.profile.optionType;
      this.type = !(this.optionTypes.map(t => t.key).includes(pType)) ? 'all' : pType;
      this.bookings = typeof this.profile.bookings !== 'undefined' ? this.profile.bookings : true;
      this.tasks = typeof this.profile.tasks !== 'undefined' ? this.profile.tasks : true;

      if (this.canView('STAFF')) {
        this.buildData();
      }
    },
    computed: {
      optionTypes() {
        return [
          {key: 'all', label: this.$t('dashboard.all_staff_on_project')},
          {key: 'active', label: this.$t('dashboard.active_staff_on_project')},
          {key: 'remaining', label: this.$t('dashboard.staff_with_remaining_work')},
          {key: 'completed', label: this.$t('dashboard.staff_completed_all_work')},
        ]
      }
    },
    methods: {
      async buildData() {
        const self = this;

        const all = [];
        const active = [];
        const remaining = [];
        const completed = [];
        let method = null;
        let methodBooking = null;
        
        if (this.type == 'all') {
          method = staffService.projectAll;
          methodBooking = staffService.projectAllBooking;
        } else if (this.type == 'active') {
          method = staffService.projectActive;
          methodBooking = staffService.projectActiveBooking;
        } else if (this.type == 'remaining') {
          method = staffService.projectRemaining;
          methodBooking = staffService.projectRemainingBooking;
        } else if (this.type == 'completed') {
          method = staffService.projectAll;
          methodBooking = staffService.projectAllBooking;
        } else {
          console.error("Unknown staff list type"); // eslint-disable-line no-console
        }
        
        self.staffs = [];
        let staffIds = [];
        
        if (this.tasks) {
          await method(this.project.uuId).then(response => {
            let staff = {}
            if (this.type == 'completed') {
              response.data.forEach(s => {
                if (!staff[s.uuId]) {
                  staff[s.uuId] = [];
                }
                staff[s.uuId].push(s);
              })
              // Only keep staff that have 100%'d all their tasks
              let keep = {};
              Object.keys(staff).forEach(key => {
                let done = true;
                let tasks = staff[key];
                tasks.forEach(t => {
                  if (t.progress != 1.0) {
                    done = false;
                  }
                })
                if (done) {
                  keep[key] = tasks[0];
                }
              });
              
              staff = keep;
            } else {
              // Only keep unique staff
              response.data.forEach(s => {
                staff[s.uuId] = s;
              })
            }
  
            staffIds = Object.values(staff).map(s => { return s.uuId });
          });
        }
        
        if (this.bookings) {
          await methodBooking(this.project.uuId).then(response => {
            let staff = {}
            if (this.type == 'completed') {
              response.data.forEach(s => {
                if (!staff[s.uuId]) {
                  staff[s.uuId] = [];
                }
                staff[s.uuId].push(s);
              })
              // Only keep staff that have 100%'d all their tasks
              let keep = {};
              Object.keys(staff).forEach(key => {
                let done = true;
                let bookings = staff[key];
                bookings.forEach(t => {
                  if (t.untilDate >= new Date().getTime()) {
                    done = false;
                  }
                })
                if (done) {
                  keep[key] = bookings[0];
                }
              });
              
              staff = keep;
            } else {
              // Only keep unique staff
              response.data.forEach(s => {
                staff[s.uuId] = s;
              })
            }
            
            for (const s of Object.values(staff)) {
              if (self.staffs.findIndex(stf => stf.uuId === s.uuId) === -1) {
                staffIds.push(s.uuId);
              }
            }
          });
        }
        
        if ((!self.bookings && !self.tasks) ||
            staffIds.length === 0) {
          self.staffs = [];
        }
        else {
          self.staffs = await staffService.list({ start: 0, limit: -1, holder: staffIds }).then(response => {
            return response.data;
          });
        }
        self.loaded = true;
      },
      saveSettings() {
        this.profile.optionType = this.type;
        this.profile.bookings = this.bookings;
        this.profile.tasks = this.tasks;
        this.$emit('saveWidget', this.profile);
      },
      onSortChanged(key, order) {
        this.profile.sortKey = key;
        this.profile.sortOrder = order;
        this.$emit('saveWidget', this.profile);
      },
      onColumnVisible(columns) {
        this.profile.columns = columns;
        this.$emit('saveWidget', this.profile);
      },
      onResize({width, height}) {
        
      },
      onOpenStaff(id) {
        this.$emit('openStaff', id);
      },
      getTypeOptionLabel(value) {
        return this.optionTypes.find(i => i.key === value)?.label || value;
      },
    }
  }
</script>

<style scoped>
  .settings {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .settings div {
    margin-left: 10px;
  }
</style>